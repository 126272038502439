<template>
  <div>
    <div class="return" v-if="detail.uid" @click="back">
      <img class="backImg" src="@/assets/images/back.png" alt />
    </div>
    <el-form ref="detail" :model="detail" :rules="rules" label-position="right" label-width="130px">
      <div class="conMsg">
        <div class="title">
          <i class="el-icon-collection-tag" />基本资料
        </div>
        <div class="form">
          <el-form-item size="mini" label="销售部门:">
            <el-input v-model="detail.shop_name" class="w200" readonly />
          </el-form-item>
          <el-form-item size="mini" label="客户姓名">
            <el-input v-model="detail.consignee" class="w200" readonly />
          </el-form-item>
          <el-form-item size="mini" label="护理师(开单人)" prop="sale_id">
            <el-select v-model="detail.sale_id" @change="changeSale" placeholder="请选择" class="w200">
              <el-option
                v-for="item in options.staff"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item size="mini" label="项目类型" prop="project_type" v-if= "isShowProjectType">
            <el-select v-model="detail.project_type" placeholder="请选择" class="w200">
              <el-option
                v-for="item in options.project_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item size="mini" label="销售组织">
            <el-select
              v-model="detail.sales_organization"
              @change="changeSales"
              placeholder="请选择"
              class="w200"
            >
              <el-option
                v-for="item in options.market"
                :key="item.marketKingdeeCode"
                :label="item.marketName"
                :value="item.marketKingdeeCode"
              />
            </el-select>
          </el-form-item>
          <el-form-item size="mini" label="发货组织">
            <el-select
              v-model="detail.settlement_organization"
              @change="changeSettlement"
              placeholder="请选择"
              class="w200"
            >
              <el-option
                v-for="item in options.market"
                :key="item.marketKingdeeCode"
                :label="item.marketName"
                :value="item.marketKingdeeCode"
              />
            </el-select>
          </el-form-item>
          <el-form-item size="mini" label="发货部门:">
            <el-input v-model="detail.delivery_name" class="w200" readonly />
          </el-form-item>
          <el-form-item size="mini" label="成交日期" prop="deal_time">
            <el-date-picker
              v-model="detail.deal_time"
              type="date"
              class="w200"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              :picker-options="maxTime"
            ></el-date-picker>
          </el-form-item>
          <el-form-item size="mini" label="订单类型" prop="order_type">
            <el-select v-model="detail.order_type" placeholder="请选择" class="w200">
              <el-option
                v-for="item in options.order_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item size="mini" label="发货仓库" prop="house_code">
            <el-select v-model="detail.house_code" class="w200" clearable @change="changeHouse">
              <el-option
                v-for="item in options.house"
                :key="item.kingdeeCode"
                :label="item.name"
                :value="item.kingdeeCode"
              />
            </el-select>
          </el-form-item>
          
          <el-form-item size="mini" label=" 订单描述 ">
            <el-input type="textarea" v-model="detail.description" class="w200" />
          </el-form-item>

          <!-- <el-form-item size="mini" label="收入类型" prop="income_type">
            <el-select
              v-model="detail.income_type"
              placeholder="请选择"
              class="w200"
            >
              <el-option
                v-for="item in options.income_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item> -->
        </div>
      </div>
      <div class="conMsg">
        <div class="title">
          <i class="el-icon-collection-tag" />商品列表
        </div>
        <!--列表-->
        <el-row type="flex" justify="space-between">
          <el-col :span="24">
            <el-table
              :data="goods"
              size="small"
              border
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
              @selection-change="handleSelected"
            >
              <el-table-column type="selection" width="50" />
              <el-table-column label="* 商品名称" width="220" prop="name">
                <template  slot-scope="scope">
                  <el-select
                    v-model="goods[scope.$index].name"
                    size="mini"
                    value-key="id"
                    placeholder="请选择商品"
                    filterable
                    @focus="selectFocus"
                    :remote-method="remoteMethod"
                    @change="val => selectChange(val, scope.$index,goods[scope.$index])"
                  >
                    <el-option
                      v-for="item in product"
                      :key="item.id"
                      :label="item.zu_name"
                      :value="item.id"
                    />
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column label="服务/商品-别名" width="200" >
                <template slot-scope="{ row }">
                  <el-input v-model="row.goods_alias_name" placeholder="请输入服务/商品-别名" size="mini"></el-input>
                </template>
              </el-table-column>

            <el-table-column label="* 业务类型" width="120">
              <template slot-scope="{ row ,$index}" >
                <span v-if="perstock_type != '3'">
                  <el-select
                    v-model="row.business_type"
                    size="mini"
                    value-key="id"
                    placeholder="请选择业务类型"
                    :disabled="row.name == '' ? true:false || row.prestock_type == 3"
                    filterable
                    @change="(val) => businessChange(row,$index)"
                  >
                    <el-option
                      v-for="(item, index) in options.business_type"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                      :disabled="item.disabled"
                    />
                  </el-select>
                </span>
                <span v-else>
                    --
                </span>
              </template>
            </el-table-column>

            <el-table-column label="* 项目二级分类" width="150">
              <template slot-scope="{ row ,$index}">
                <span v-if="perstock_type != '3'">
                  <el-select
                    v-model="row.business_two_type"
                    size="mini"
                    value-key="id"
                    placeholder="项目二级分类"
                   :disabled="row.business_type ==2 ?false:true"
                    filterable
                    @change="(val) => businessChange(row,$index)"
                  >
                    <el-option
                      v-for="(item, index) in options.business_two_type"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </span>
                <span v-else>
                    --
                </span>
              </template>
            </el-table-column>

              <el-table-column label="服务/商品">
                <template slot-scope="{ row }">
                  <div v-if="row.is_project == 1">商品</div>
                  <div v-if="row.is_project == 2">服务</div>
                </template>
              </el-table-column>
              <el-table-column label="规格" prop="spec_name" />
              <el-table-column label="* 是否赠品">
                <template slot-scope="{ row, $index }">
                  <span v-if="row.prestock_type != '3'">
                    <el-checkbox
                      v-model="row.checked"
                      @change="value => changeGift(value, $index)"
                    >
                    是</el-checkbox>
                  </span>
                  <span v-else> -- </span>
                 
                </template>
              </el-table-column>
              <el-table-column label="标准价格" prop="goods_price" />

              <el-table-column label="* 销售价格" width="160">
                <template scope="{ row, $index }">
                  <span v-if = "row.prestock_type == '3'">
                      --
                  </span>
                  <span v-else>
                    <el-input-number
                    v-if="row.is_gift == 0  "
                    v-model="row.pay_price"
                    :precision="2"
                    size="mini"
                    :min="0.00"
                    @change="(value) => priceChange(value, $index,'goods')"
                  />
                  <el-input-number
                    v-else
                    v-model="row.pay_price"
                    :min="0.00"
                    :precision="2"
                    size="mini"
                    disabled
                    @change="(value) => priceChange(value, $index,'goods')"
                  />
                  </span>
                  
                </template>
              </el-table-column>
              <el-table-column label="* 销售数量" width="160">
                <template scope="{ row, $index }">
                  <!-- is_project -->
                  <el-input-number
                    v-if="row.is_project == 1 && row.kingdee_code != '101.12.099'&& row.kingdee_code != '101.12.098' && row.prestock_type != '3'"
                    v-model="row.number"
                    :precision="2"
                    size="mini"
                    :min="0.01"
                    @change="(value) => handleChange(value, $index, 'goods')"
                  />
                  <el-input-number
                    v-if="(row.is_project == 2 || row.kingdee_code == '101.12.099') && row.kingdee_code != '101.12.098' && row.prestock_type != '3' "
                    v-model="row.number"
                    size="mini"
                    :precision="0"
                    :min="1"
                    @change="(value) => handleChange(value, $index, 'goods')"
                  />
                  <el-input-number
                    v-if="row.kingdee_code == '101.12.098' && row.perstock_type != '3'"
                    v-model="row.number"
                    size="mini"
                    
                    @change="(value) => handleChange(value, $index, 'goods')"
                  />
                  <!-- <span v-if="row.prestock_type == '3'">--</span> -->
                </template>
              </el-table-column>
              <el-table-column  width ="120" >
                <template slot="header">
                  <div style="color: red;">
                    *预存货选项
                  </div>
                </template>
                
                <template slot-scope="{ row ,$index }">
                  <el-select v-model="row.prestock_type"  
                  size="mini" 
                  :disabled="row.name == '' || row.kingdee_code == '101.12.098' ? true:false"
                  @change="(value) => businessPrestockTypeChange(goods[$index], $index)"
                  placeholder="请选择"
                  >
                    <el-option
                      v-for="(item, index) in options.is_perstock"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="* 存/出货数量" width = "150">
                <template slot-scope="{ row,$index}">
                    <div v-if="row.prestock_type == '1' || row.prestock_type == '3' || row.prestock_type == '' "> {{row.out_num}}</div>
                    <div v-if="row.prestock_type == '3'">
                      <el-button type="text" @click =perstockGoodsDetail(row,$index)>查看</el-button>
                    </div>
                    <div v-if="row.prestock_type == '2'">
                      <el-input-number
                        v-model="row.out_num"
                        :precision="2"
                        :step="0.01"
                        size="mini"
                        :min="0.00"
                        :max="row.number"
                      />
                    </div>
                </template>
              </el-table-column>

             
              <el-table-column label="销售金额" prop="littleCount" />

              <el-table-column label="* 商品/服务类型" width="150">
                <template slot-scope="{ row }">
                  <el-radio
                    v-if="row.is_project == 2 &&( row.kingdee_code != '101.12.098' &&  row.kingdee_code != '101.12.099')"
                    v-model="row.project_type"
                    :label="1"
                  >按次</el-radio>
                  <el-radio
                    v-if="row.is_project == 2 && (row.kingdee_code != '101.12.098' && row.kingdee_code != '101.12.099')"
                    v-model="row.project_type"
                    :label="2"
                  >按天</el-radio>
                  <span v-if="row.is_project == 1  && (row.kingdee_code != '101.12.098' && row.kingdee_code != '101.12.099')">--</span>
                </template>
              </el-table-column>
              <el-table-column label="* 服务次/天数" width="250">
                <template scope="{ row, $index }">
                  <el-input
                    v-if="
                      row.project_type == 1 &&
                      row.is_project == 2
                    "
                    v-model="row.service_number"
                    class="w150"
                    size="mini"
                    type="span"
                  >
                    <span slot="append">次</span>
                  </el-input>
                  <el-date-picker
                    v-model="row.serveData"
                    type="daterange"
                    v-if="
                      row.project_type == 2 &&
                      row.is_project == 2
                    "
                    size="mini"
                    class="w220"
                    @change="(value) => timeChange(value, $index, 'goods')"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                  <span v-if="row.is_project == 1">--</span>
                </template>
              </el-table-column>
              <el-table-column label="* 陪护师一-小项" width="400">
                <template scope="{ row, $index }">
                  <el-select v-model="row.nurse_one" size="mini" placeholder="请选择" class="w100"  v-if="row.is_project == 2" >
                    <el-option
                      v-for="item in options.staff"
                        size="mini"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select v-model="row.nurse_one_project"  size="mini" multiple  placeholder="请选择" class="w200"  v-if="row.is_project == 2" >
                    <el-option
                      v-for="item in options.nurse_one_project"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                
                  <span v-if="row.is_project == 1">--</span>
                </template>
              </el-table-column>
              <el-table-column label="* 陪护师二-小项" width="400" >
                <template scope="{ row, $index }">
                  <el-select v-model="row.nurse_two"  size="mini" placeholder="请选择" class="w100"  v-if="row.is_project == 2" > 
                    <el-option
                      v-for="item in options.staff"
                        size="mini"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select v-model="row.nurse_two_project"  size="mini" multiple  placeholder="请选择" class="w200"  v-if="row.is_project == 2" :key="$index">
                    <el-option
                      v-for="item in options.nurse_two_project"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <span v-if="row.is_project == 1">--</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="flexSb mt20">
              <div>
                <el-button
                  type="success"
                  icon="el-icon-plus"
                  size="mini"
                  @click="handleProduct('add')"
                >新增</el-button>
                <el-button
                  type="danger"
                  icon="el-icon-minus"
                  size="mini"
                  @click="handleProduct('reduce')"
                >移除</el-button>
              </div>
              <div class="mr20">
                合计:
                <span class="peril">{{ detail.orderTotal }}元</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="submit">
        <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('detail')">提交订单</el-button>
      </div>
    </el-form>
    <el-dialog
      title="结算统计"
      :visible.sync="privilegePopup"
      :close-on-click-modal="false"
      width="1000px"
      @close="closeTicket"
    >
      <div v-if="leadData">
        <div class="mb10 fwb" style="display: flex;justify-content: space-between;">
          <span>请选择优惠券:</span>
          <el-button size="mini" type="primary" @click="totalMoney('clearGoods')">不使用优惠券</el-button>
        </div>
        <div class="privilegeList">
          <div
            v-for="item in leadData"
            :key="item.id"
            @click="tapPrivilege(item)"
            :class="item.choice?'':'choice' "
            class="privilegeItem"
          >
            <div class="privilegeL">
              <div class="privilegeMoney">￥{{item.denomination}}</div>
              <div class="round1"></div>
              <div class="round2"></div>
            </div>
            <div class="privilegeR">
              <div class="privilegeName">
                <span>{{item.name}}</span>
                <span v-if="item.type==1" class="fz12">类型：满减券</span>
                <span v-if="item.type==3" class="fz12">类型：满赠券</span>
              </div>
              <div class="privilegeFull">
                使用门槛：
                <span v-if="item.use_threshold>0">满{{item.use_threshold}}可用</span>
                <span v-else>无门槛</span>
              </div>
              <div class="privilegeTime">
                有效期：
                <span v-if="item.end_time">{{item.start_time}}至{{item.end_time}}</span>
                <span v-else>无限制</span>
              </div>
            </div>
          </div>
        </div>
        <el-row type="flex" justify="space-between" class="mt20">
          <el-col :span="24">
            <el-table
              :data="privilegeList"
              size="small"
              border
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
            >
              <ElTableColumn label="商品名称" prop="name" fixed width="160" />
              <el-table-column label="服务/商品">
                <template slot-scope="{ row }">
                  <div v-if="row.is_project == 1">商品</div>
                  <div v-if="row.is_project == 2">服务</div>
                </template>
              </el-table-column>
              <el-table-column label="规格" prop="spec_name" />
              <el-table-column label="标准价格" prop="goods_price" />
              <el-table-column label="* 销售价格" width="100">
                <template scope="{$index}">
                  <span v-if="privilegeType == 1">{{privilegeList[$index].goods_price}}</span>
                  <span v-if="privilegeType == 3">0</span>
                </template>
              </el-table-column>
              <el-table-column label="* 数量" width="160">
                <template scope="{$index}">
                  <!-- <el-input-number
                    v-if="privilegeType == 3"
                    v-model="privilegeList[$index].number"
                    :precision="2"
                    size="mini"
                    @change="(value) => handleChange(value, $index,'privilege')"
                  />
                  <span v-if="privilegeType == 1">-1</span> -->
                  <span v-if="privilegeType == 1 || privilegeType == 3">-1</span> 
                </template>
              </el-table-column>
              <el-table-column label="销售金额" prop="littleCount" />
              <el-table-column label="* 是否赠品" size="mini" width="100">
                <template>
                  <span>赠品</span>
                </template>
              </el-table-column>
              <el-table-column label="* 商品/服务类型" width="150">
                <template slot-scope="{ row }">
                  <el-radio
                    v-if="row.is_project == 2 && (row.kingdee_code != '101.12.098' && row.kingdee_code != '101.12.099')"
                    v-model="row.project_type"
                    label="1"
                  >按次</el-radio>
                  <el-radio
                    v-if="row.is_project == 2 && (row.kingdee_code != '101.12.098' && row.kingdee_code != '101.12.099')"
                    v-model="row.project_type"
                    label="2"
                  >按天</el-radio>
                  <span v-if="row.is_project == 1 && (row.kingdee_code != '101.12.098' && row.kingdee_code != '101.12.099')">--</span>
                </template>
              </el-table-column>
              <el-table-column label="* 服务次/天数" width="300">
                <template slot-scope="{ row }">
                  <el-input
                    v-if="
                      row.project_type == 1 &&
                      row.is_project == 2
                    "
                    v-model="row.service_number"
                    class="w150"
                    size="mini"
                    type="span"
                  >
                    <span slot="append">次</span>
                  </el-input>
                  <el-date-picker
                    v-model="row.start_time"
                    type="date"
                    v-if="
                      row.project_type == 2 &&
                      row.is_project == 2
                    "
                    size="mini"
                    class="w130"
                    placeholder="开始日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>

                  <el-date-picker
                    v-model="row.end_time"
                    type="date"
                    v-if="
                      row.project_type == 2 &&
                      row.is_project == 2
                    "
                    size="mini"
                    class="w130"
                    placeholder="结算日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                  <span v-if="row.is_project == 1">--</span>
                </template>
              </el-table-column>
              <el-table-column label="* 陪护师一-小项" width="400">
                <template scope="{ row, $index }">
                  <el-select v-model="detail.nurse_one"    size="mini" placeholder="请选择" class="w100">
                    <el-option
                      v-for="item in options.staff"
                        size="mini"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select v-model="detail.nurse_one_project"  size="mini" multiple  placeholder="请选择" class="w200">
                    <el-option
                      v-for="item in options.nurse_one_project"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                
                  <span v-if="row.is_project == 1">--</span>
                </template>
              </el-table-column>
              <el-table-column label="* 陪护师二-小项" width="400">
                <template scope="{ row, $index }">
                  <el-select v-model="detail.nurse_two"    size="mini" placeholder="请选择" class="w100">
                    <el-option
                      v-for="item in options.staff"
                        size="mini"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select v-model="detail.nurse_two_project"  size="mini" multiple  placeholder="请选择" class="w200">
                    <el-option
                      v-for="item in options.nurse_two_project"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <span v-if="row.is_project == 1">--</span>
                </template>
              </el-table-column>

            </el-table>
            <div class="flexSb mt20">
              <div></div>
              <div class="mr20 mb20">
                优惠合计:
                <span class="peril">{{ privilegeTotal }}元</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="text-align: right;" class="fwb">
        （
        <span>实收本金：￥{{detail.business_type == 1?accounts.zc_goods_y:accounts.zc_fuwu_y}}</span>
        +
        <span>实收赠金：￥{{detail.business_type == 1?accounts.zc_goods_z:accounts.zc_fuwu_z}}</span>
        +
        <span>实收店转：￥{{detail.business_type == 1?accounts.zc_goods_d:accounts.zc_fuwu_d}}</span>
        )

        <span class="ml20">
          实收总额：
          <span
            class="peril"
          >￥{{detail.business_type == 1?accounts.zc_allgoods_z:accounts.zc_allfuwu_z}}</span>
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="isPrivilege">确定提交</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="预存货列表"
      :visible.sync="prestockPopup"
      :close-on-click-modal="false"
      width="1000px"
      @close="closePrestockTicket"
    >
      <div>
        <el-row type="flex" justify="space-between" class="mt20">
          <el-col :span="24">
            <el-table
              :data="prestockList"
              size="small"
              border
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
            >
              <ElTableColumn label="商品名称" prop="goods_name" fixed width="160" />
              <el-table-column label="商品类型" prop="is_project" >
                <template slot-scope="{ row }">
                  <span v-if="row.is_project == 1">商品</span>
                  <span v-if="row.is_project == 2">项目</span>
                </template>
              </el-table-column>
              <el-table-column label="规格" prop="spec_name" />
              <el-table-column label="标准价格" prop="goods_price" />
              <el-table-column label="销售单价" prop="pay_price" />
              <el-table-column label="销售金额" prop="littleCount" />
              <el-table-column label="预存时间" prop="prestock_time" />
              <el-table-column label="剩余预存量" prop="max_num" />
              <el-table-column label="* 出货数量" width="160">
                <template scope="{row}">
                  <el-input-number
                    v-model="row.shipment_num"
                    :precision="2"
                    size="mini"
                    :step="0.01"
                    :max="parseFloat(row.max_num)"
                    @change="(value) => handleChange(value , '' ,'prestock')"
                  />
                </template>
              </el-table-column>
            </el-table>
            <div class="flexSb mt20">
              <div></div>
              <div class="mr20 mb20">
                数量合计:
                <span class="peril">{{ sum_total }}个</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="prostockShipmentVerify()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { goodsList2,goodsControls} from '@/api/goods'
import { getCardDetail } from '@/api/card'
import { staffListS } from '@/api/staff'
import { shopMarket, shopStore, shopDetail } from '@/api/shop'
import { goodsDetailList,prostockShipmentVerify } from '@/api/prestock'
import {
  createOrder,
  getPayment,
  getCouponProvide,
  saleList
} from '@/api/order'
import { prestockList } from '../../api/prestock'
class Detail {
  shop_id = ''; // 门店id
  shop_name = ''; // 门店名称
  uid = ''; // 客户id
  consignee = ''; // 客户名称
  deal_time = ''; // 订单成交日
  business_type = ''; // 业务类型
  business_two_type = '';//业务类型 二级分类
  income_type = ''; // 收入类型
  sale_id = ''; // 销售员
  sale_name = ''; // 销售员姓名
  market_id = ''; // 市场id
  market_name = ''; // 市场姓名
  sales_organization = ''; // 销售组织编码
  sales_name = ''; // 销售组织名称
  settlement_organization = ''; // 发货组织编码
  settlement_name = ''; // 发货组织名称
  delivery_name = ''; // 发货部门
  delivery_king = ''; // 发货部门编码
  house_code = ''; // 发货仓库
  house_code_name = '';
  pay_amount = ''; // 实收金额
  discount_amount = ''; // 实收金额
  order_type = ''; // 订单类型
  project_type = '';//项目类型
  coupon_id = 0; // 优惠券id
  coupon_name = ''; // 优惠券名称
  description = ''; // 订单描述
  sales_policy = ''; // 促销政策
  is_experience = '0'; // 是否体验
  experience_id = ''; // 体验人id
  experience_name = ''; // 体验人姓名
  is_outreach = '0'; // 是否外联
  outreahc_id = ''; // 外联人id
  outreahc_name = ''; // 外联人姓名
  is_introduction = '0'; // 是否转介绍
  introduction_id = ''; // 转介绍id
  introduction_name = ''; // 转介绍姓名
  orderTotal = 0;
  yhj_id = '';
  goodsList = [];
  nurse_one = '';//护理师1的名字
  nurse_one_project = [];
  nurse_two = '';//护理师2的名字
  nurse_two_project = [];



}
class Options {
  shop_id = [];
  market = [];
  uid = [];
  sale_id = [];
  nurse_one_project = []; //护士产生的小项
  nurse_two_project = []; //护士产生的小项
  business_type = [
    { id: 1, name: '疤痕' },
    { id: 2, name: '项目' },
    { id: 3, name: "青春痘" },
    { id: 4, name: "护肤品" }
  ];
  is_perstock  =[
    {id:1, name:'不存货'},
    {id:2, name:'存货'},
    {id:3, name:'出存货'},
  ];


  income_type = [
    { id: 1, name: '服务（各类疗程卡项及疗程卡项赠送产品）' },
    { id: 2, name: '产品销售（单独付费购买产品）' },
	{ id: 3, name: '医美收入' }
  ];

  order_type = [
    { id: 1, name: '新单' },
    { id: 2, name: '补单' }
  ];

  project_type = [
	  { id: 1, name: '手术' },
	  { id: 2, name: '注射' },
	  { id: 3, name: '光电' },
    { id: 4, name: '生美皮肤' },
    { id: 5, name: '身体' },
	];
  business_two_type = [
    { id: 1, name: '身体项目' },
    { id: 2, name: '皮肤项目' },
  ];

  outreahc_id = [];
  introduction_id = [];
  sales_policy = [];
  props = {
    label: 'name',
    value: 'obj',
    children: 'options',
    emitPath: false
  };

  house = [];
  staff = [];
}
class Rules {
  shop_id = [{ required: true, message: '请输入门店名称', trigger: 'change' }];
  uid = [{ required: true, message: '请选择客户', trigger: 'change' }];
  sale_id = [{ required: true, message: '请选择开单人', trigger: 'change' }];
  business_type = [
    { required: true, message: '请选择业务类型', trigger: 'change' }
  ];

  income_type = [
    { required: true, message: '请选择收入类型', trigger: 'change' }
  ];

  order_type = [
    { required: true, message: '请选择订单类型', trigger: 'change' }
  ];

  sales_organization = [
    { required: true, message: '请选择销售组织', trigger: 'change' }
  ];

  settlement_organization = [
    { required: true, message: '请选择发货组织', trigger: 'change' }
  ];

  house_code = [
    { required: true, message: '请选择发货仓库', trigger: 'change' }
  ];

  deal_time = [
    { required: true, message: '请选择成交日期', trigger: 'change' }
  ];

  experience_id = [
    { required: true, message: '请选择体验人', trigger: 'change' }
  ];

  outreahc_id = [
    { required: true, message: '请选择外联人', trigger: 'change' }
  ];

  introduction_id = [
    { required: true, message: '请选择转介绍人', trigger: 'change' }
  ];
}
class Goods {
  ids = Math.round(Math.random() * 1234567890);
  id = '';
  name = '';
  is_coupon_goods = 0; // 是否优惠券商品0否 1是
  is_project = '';
  kingdee_code = '';
  spec_name = '';
  goods_price = '';
  pay_price = 0;
  number = 0;
  project_type = 1;
  prestock = [];
  is_gift = 0;
  checked = false;
  house_code = '';
  service_number = '';
  start_time = '';
  end_time = '';
  unit_code = '';
  littleCount = 0;//销售金额
  serveData = []; // 服务天数
  out_num = 0; //出/存货数量
  prestock_type = 1; //默认不出货
  business_type = ''; // 业务类型
  business_two_type = '';//业务类型 二级分类
}
export default {
  data () {
    return {
      detail: new Detail(), // 信息
      options: new Options(), // 选项
      rules: new Rules(), // 表单校验
      leadData: [],
      data: [{}],
      goods: [new Goods()], // 商品列表
      product: [], // 商品列表
      select: [], // 选择id

      privilegeData: '', // 优惠券服务天数
      loading: false, // resource
      privilegePopup: false, // 优惠券弹窗
      privilegeList: [], // 优惠券详情商品列表
      privilegeType: '', // 优惠券详情类型
      privilegeTotal: 0, // 优惠券合计
      prestockPopup: false,// 预存货列表弹窗
      prestockList: [], // 预存货商品列表
      shipment : 0, //预存货出货数量
      accounts: { yhj_list: [] }, // 结算清单
      maxTime:{
        disabledDate(time){
          return time.getTime()>Date.now();
        }
      },
      isShowBusinessTwoType:false,
      isShowProjectType:false,
      perstock_type:1,
      sum_total:0,//预存货出货数量
      now_index:0 // 当前商品下标
    }
  },
  mounted () {
    this.detail.shop_id = this.$route.query.detail.shop_id
    this.detail.shop_name = this.$route.query.detail.shop_name
    this.detail.uid = this.$route.query.detail.id
    this.detail.consignee = this.$route.query.detail.customer_name
    console.log(this.detail)
    this.getList()
  },
  methods: {
    business_type(e){
      if(e==2){
        this.isShowBusinessTwoType = true;
        this.detail.business_two_type =2;
      }else{
        this.isShowBusinessTwoType = false;
        this.detail.business_two_type ='';
      }
    },
    // 获取详情
    getList () {
      // 客户所在店铺员工列表
      staffListS({ page: 1, limit: 1000000, shopId: this.detail.shop_id }).then(
        res => {
          this.options.staff = res.list
        }
      )
      // 组织
      shopMarket({ shopId: this.detail.shop_id }).then(res => {
        this.options.market = res
        this.detail.sales_organization = this.options.market[0].marketKingdeeCode
        this.detail.sales_name = this.options.market[0].marketName
        this.detail.settlement_organization = this.options.market[0].marketKingdeeCode
        this.detail.settlement_name = this.options.market[0].marketName
      })
      this.detail.sale_id = this.$route.query.detail.nurse_id
      this.detail.sale_name = this.$route.query.detail.nurse_name

      // 该门店仓库
      shopStore({ page: 1, limit: 100000, shopId: this.detail.shop_id }).then(
        res => {
          this.options.house = res.list
        }
      )
      // 促销政策
      saleList().then(res => {
        this.options.sales_policy = res.data.list
      })
      this.changeShop(this.detail.shop_id)
      this.getGoods()
    },
    // 商品数据
    getGoods (name) {
      goodsList2({ page: 1, limit: 100000, zu_name: name || '',status:1 }).then(
        res => {
          this.product = res.list
        }
      ),
      goodsControls({ page: 1, limit: 100000}).then(
        res => {
          this.options.nurse_one_project = res.list
          this.options.nurse_two_project = res.list
        }
      )

    },
    // 更改删除项
    handleSelected (row) {
      this.select = row.map(val => val.id)
    },

    // 商品选择前
    selectFocus () {
      if (!this.detail.uid) return this.$message.warning('请先选择客户哦！')
    },
    // 商品操作
    handleProduct (type) {
      switch (type) {
        case 'add':
          let tip = 0
          this.goods.forEach(item => {
            if (item.id == '') {
              tip += 1
            }
          })
          if (tip > 0) {
            this.$message({
              message: '请补全列表中为空商品。',
              type: 'warning'
            })
          } else {
            this.goods.push(new Goods())
          }
          break
        case 'reduce':
          if (this.select.length === 0) {
            this.$message({
              message: '请选择想要移除的商品项。',
              type: 'warning'
            })
          }
          this.goods = this.goods.filter(
            val => this.select.findIndex(item => item === val.id) < 0
          )
          this.getSummaries('goods')
          break
      }
    },
    // 销售结果更改
    priceChange (value, index, type) {
      if (type == 'goods') {
        this.goods[index].littleCount = (
          value * this.goods[index].number
        ).toFixed(2)
        this.getSummaries(type)
      } else {
        this.privilegeList[index].littleCount = (
          value * this.privilegeList[index].number
        ).toFixed(2)
        this.getSummaries(type)
      }
    },
    
    // 商品数量
    handleChange (value, index, type, scope) {
      if (type == 'goods') {
        this.goods[index].littleCount = (
          value * this.goods[index].pay_price
        ).toFixed(2)
        this.goods[index].out_num = value
        this.getSummaries('goods')
      } else if(type == 'prestock'){
        this.sum_total = 0
        this.prestockList.forEach(item => {
          this.sum_total = (
            parseFloat(this.sum_total ? this.sum_total : 0) +
            parseFloat(item.shipment_num ? item.shipment_num : 0)
          ).toFixed(2)
        })
      }else{
        console.log(value,'row');
        this.privilegeList[index].littleCount = (
          value * this.privilegeList[index].pay_price
        ).toFixed(2)
        this.getSummaries('privilege')
      }
    },
    // 搜索商品
    remoteMethod (e) {
      this.getGoods(e)
    },
    //分类改变时间
    businessChange(row,index){

      if(row.business_type != 2){
        row.business_two_type = '';
      }
      this.getSummaries('goods')

    //  let isRepeat = this.goodsUniqueVerify(this.goods[index],index)
    //  if(!isRepeat){
    //   this.getSummaries('goods')
    //  }

    },
    // 选择商品
    selectChange (value,index,row) {
      
      const cur = this.product.find(item => item.id == value)

      this.$set(this.goods, index, Object.assign({ is_gift: 0 }, this.goods[index], cur))
   

      if(this.goods[index].prestock_type == 3) {
        this.goods[index].number = 0.00
        this.goods[index].out_num = 0.00
      }else if(this.goods[index].prestock_type == 2){
        this.goods[index].number = 1.00
        this.goods[index].out_num = 0.00
      }else {
        this.goods[index].number = 1.00
        this.goods[index].out_num = 1.00
      }
  

      if(this.goods[index].kingdee_code == '101.12.098'){
        this.goods[index].number = -1
        this.goods[index].out_num = this.goods[index].number 
        this.goods[index].prestock_type = 1

      }

      this.goods[index].prestock = [];
      this.prestockList = []
      this.goods[index].pay_price =  this.goods[index].goods_price
      this.goods[index].littleCount = (
        this.goods[index].pay_price * this.goods[index].number
        ).toFixed(2)
      this.getSummaries('goods')

      // let isRepeat = this.goodsUniqueVerify(this.goods[index],index)
      // if(isRepeat){
      //   this.handleChange (this.goods[index].number, index, 'goods')
      // }else{
      //   this.getSummaries('goods')
      // }
      console.log(this.goods,"商品名称")
    },

  

    //预存货选项
    prestoreSelectChange(val,index,row){
    
      this.perstock_type = val;

      // this.goodsUniqueVerify(row,index)

      if(val == 3){
        this.goods[index].number = 0
        this.goods[index].out_num = 0
      }
      if(val == 1){
        this.goods[index].out_num = this.goods[index].number;
      }
    },
    businessPrestockTypeChange(e,index){
      console.log(this.goods,"预存货选项1")

      // this.prestoreSelectChange()
      this.now_index = index
      this.goods[this.now_index].prestock = [];
      this.prestockList = [];
      if(e.prestock_type == 3){
        this.goods[this.now_index].pay_price = 0;
        this.goods[this.now_index].number = 0;
        this.goods[this.now_index].littleCount = 0;
        this.goods[this.now_index].out_num = 0;
        this.goods[this.now_index].business_type = '';
        this.goods[this.now_index].business_two_type = '';
        this.goods[this.now_index].checked = '';
        this.goods[this.now_index].is_gift = 0
      } else if(e.prestock_type == 2){
        this.goods[this.now_index].number = 1;
        this.goods[this.now_index].out_num = 0;
        this.goods[this.now_index].littleCount =  this.goods[this.now_index].pay_price * this.goods[this.now_index].number ;
      } else if(e.prestock_type == 1){
        this.goods[this.now_index].number = 1;
        this.goods[this.now_index].out_num = 1;

      }

      // let isRepeat = this.goodsUniqueVerify(e,index);
      // if(isRepeat){
      //   this.goods[this.now_index].shop_transfer_price = 0;
      //   this.goods[this.now_index].gift_price = 0;
      //   this.goods[this.now_index].refund_principal_price = 0;
      //   this.goods[this.now_index].sales_total = 0;
      // }
      console.log(this.goods,"预存货选项2")
      this.getSummaries('goods')

    },
    //商品列表商品唯一验证
    goodsUniqueVerify(row,index){

      if(this.goods.length > 1){
        let code = 0;
        this.goods.forEach((item,index1) => {
          console.log(item.id, row.id,  item.business_type , row.business_type, item.business_two_type , row.business_two_type ,item.prestock_type, row.prestock_type, index1 ,index)
          if (item.id == row.id && item.prestock_type == row.prestock_type && item.business_type == row.business_type&& item.business_two_type == row.business_two_type && index1 !== index) {
            this.$message({
              message: '该商品已经选过',
              type: 'error'
            });
            this.goods.pop(index)
            code+=1
          }
        })
        
        if(code>0){
          return false;
        }else{
          return true;
        }
      }else {
        return true;
      }
    },
    //单出预存货验证
    prostockShipmentVerify(){
      prostockShipmentVerify({ prestock_goods:this.prestockList }).then(
        res => {
          this.prestockPopup = false;
          this.goods[ this.now_index].out_num = res.data.num
          this.goods[ this.now_index].littleCount = res.data.price;
          this.goods[ this.now_index].prestock = this.prestockList; 
        }
      )
      console.log(this.goods,"确认预存货")
    },
    // 是否赠品
    changeGift (value, index) {
      this.goods[index].pay_price = 0
      // this.goods[index].number = 1
      this.goods[index].is_gift = this.goods[index].checked ? 1 : 0
      this.priceChange(this.goods[index].pay_price, index, 'goods')
    },
    // 合计计算
    getSummaries (type, againCount) {
      console.log(this.goods,'123123777777');
      if (type == 'goods') {
        console.log(this.goods,'123123');
        this.detail.orderTotal = 0
        this.goods.forEach(item => {
          this.detail.orderTotal = (
            parseFloat(this.detail.orderTotal ? this.detail.orderTotal : 0) +
            parseFloat(item.littleCount ? item.littleCount : 0)
          ).toFixed(2)
        })
      } else {
        this.privilegeTotal = 0
        this.privilegeList.forEach(item => {
          this.privilegeTotal = (
            parseFloat(this.privilegeTotal ? this.privilegeTotal : 0) +
            parseFloat(item.littleCount ? item.littleCount : 0)
          ).toFixed(2)
        })
        this.privilegeTotal = Math.abs(this.privilegeTotal)
        if (againCount == 'againCount') {
          this.totalMoney('againCount')
        }
      }
    },
    // 改变开始结束时间
    timeChange (value, index, type) {
      if (type == 'goods') {
        this.goods[index].start_time = this.goods[index].serveData[0]
        this.goods[index].end_time = this.goods[index].serveData[1]
      } else {
        this.privilegeList[index].start_time = this.privilegeList[
          index
        ].privilegeData[0]
        this.privilegeList[index].end_time = this.privilegeList[
          index
        ].privilegeData[1]
      }
    },
    // 选择门店
    changeShop (id) {
      shopDetail({ shopId: id }).then(res => {
        this.detail.market_id = res.marketId
        this.detail.market_name = res.marketName
        this.detail.delivery_name = res.shopName
        this.detail.delivery_king = res.keycode
      })
    },
    // 搜索客户之前
    focusUid () {
      if (!this.detail.shop_id) {
        this.$message({
          message: '请先选择门店哦~',
          type: 'warning'
        })
      }
    },

    // 选择体验人
    changeExperiencc (e) {
      this.options.staff.forEach(item => {
        if (e == item.id) {
          this.detail.experience_name = item.name
        }
      })
    },
    // 选择外联人
    changeOutreahc (e) {
      this.options.staff.forEach(item => {
        if (e == item.id) {
          this.detail.outreahc_name = item.name
        }
      })
    },
    // 选择转介绍人
    changeIntroduction (e) {
      this.options.staff.forEach(item => {
        if (e == item.id) {
          this.detail.introduction_name = item.name
        }
      })
    },
    // 选择转开单人
    changeSale (e) {
      this.options.staff.forEach(item => {
        if (e == item.id) {
          this.detail.sale_name = item.name
        }
      })
    },
    // 选择销售组织
    changeSales (e) {
      this.options.market.forEach(item => {
        if (e == item.marketKingdeeCode) {
          this.detail.sales_name = item.marketName
        }
      })
    },
    // 选择发货组织
    changeSettlement (e) {
      this.options.market.forEach(item => {
        if (e == item.marketKingdeeCode) {
          this.detail.settlement_name = item.marketName
        }
      })
    },

    // 选择仓库
    changeHouse (e) {
      this.options.house.forEach(item => {
        if (e == item.kingdeeCode) {
          this.detail.house_code_name = item.name
        }
      })
    },

    // 确定新增
    submitForm (formName) {
      this.detail.goodsList = this.goods.filter(val => val.id)
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.checking()
        } else {
          return false
        }
      })
    },
    // 提交校验
    checking () {
      if (this.detail.goodsList == 0) {
        this.$message({
          message: '请添加商品',
          type: 'error'
        })
      } else {
        let number = 0
        let pay_price = 0
        let project_type = 0
        let is_gift = 0
        let service_number = 0
        let start_time = 0
        this.detail.goodsList.forEach(item => {
          if (!item.out_num) {
            number += 1
          }
          if (item.pay_price === '' && item.is_gift != 1) {
            pay_price += 1
          }
          if (item.is_gift == 2) {
            is_gift += 1
          }

          if (item.is_project == 2 && item.id != 170 && item.id != 171) {
            if (!item.project_type) {
              project_type += 1
            }
            if (item.project_type == 1 &&  !item.service_number &&  item.id != 170 && item.id != 171) {
              service_number += 1
            }
            if (item.project_type == 2 && !item.start_time) {
              start_time += 1
            }
          }
        })
        if (
          pay_price > 0 ||
          is_gift > 0 ||
          project_type > 0 ||
          service_number > 0 ||
          start_time > 0
        ) {
          this.$message({
            message: '请完善商品列表',
            type: 'error'
          })
        } else if (number > 0) {
          this.$message({
            message: '商品数量不可为0',
            type: 'error'
          })
        } else {
          this.totalMoney()
        }
      }
    },
    // 请求合计
    totalMoney (againCount) {
      const sendData = {}
      getPayment({
        goods :this.goods,
        order_amout: this.detail.orderTotal,
        customer_id: this.detail.uid,
        yhj_amount: this.privilegeTotal,
        business_type: this.detail.business_type,
        coupon_id:this.detail.coupon_id 
      }).then(res => {
        if (againCount == 'againCount') {
          this.accounts = res.data
        } else if (againCount == 'clearGoods') {
          this.detail.coupon_id =0
          this.detail.coupon_name =''
          this.accounts = res.data
          const arr = res.data.yhj_list
          arr.forEach(item => {
            item.choice = true
          })
          this.leadData = arr
          this.privilegeList = []
          this.getSummaries('privilege', 'againCount')
        } else {
          this.accounts = res.data
          const arr = res.data.yhj_list
          arr.forEach(item => {
            item.choice = true
          })
          this.leadData = arr
          this.privilegePopup = true
        }
      }).catch(()=> {
        this.privilegeList = []
      })
    },
    getFavorable () {
      this.privilegePopup = true
      getCouponProvide({ customer_id: this.detail.uid }).then(res => {
        let leadData = []
        if (this.detail.business_type == 1) {
          leadData = res.data.filter(val => val.use_type == 1)
        } else {
          leadData = res.data.filter(val => val.use_type == 2)
        }
        leadData.forEach(item => {
          item.choice = true
        })
        this.leadData = leadData
      })
    },
    // 关闭弹窗
    closeTicket (e) {
      this.detail.coupon_id = 0
      this.privilegeTotal = 0
      this.leadData = []
      this.privilegeList = []
    },
    //单个预存货商品查看
    perstockGoodsDetail(e,index){
      console.log(index,"row")
      this.sum_total = 0
      this.now_index = index
      goodsDetailList({ good_id: e.id,customer_id:this.detail.uid,shop_id:this.detail.shop_id}).then(res => {
        this.prestockPopup = true;
        this.prestockList = res.data
      })

    },
    // 关闭预存货弹窗
    closePrestockTicket (e) {
      this.privilegeTotal = 0
      this.leadData = []
      this.privilegeList = []
    },

    // 点击优惠券
    tapPrivilege (val) {
      this.leadData.forEach(item => {
        if (item.coupon_id != val.coupon_id) {
          item.choice = false
        } else {
          item.choice = true
          this.detail.coupon_id = item.coupon_id
          this.detail.coupon_name = item.name
        }
      })
      this.privilegeList = []
      this.privilegeTotal = 0
      this.getPrivilegeDetail(val)
    },
    // 优惠券详情
    getPrivilegeDetail (val) {
      getCardDetail({ id: val.coupon_id, type: 1 }).then(res => {
        this.privilegeType = res.type
        // this.getSummaries('privilege', 'againCount')

        const list = res.goodsList
        list.forEach(item => {
          item.number = -1
          item.privilegeData = ''
          item.start_time = ''
          item.end_time = ''
          item.is_coupon_goods = 1
          item.is_gift = '1'
          item.desc = '创建订单'
          item.coupon_type = this.privilegeType
          item.house_code = this.detail.house_code
          item.house_code_name = this.detail.house_code_name
          if (this.privilegeType == 3) {
            item.pay_price = 0
            item.littleCount = 0
          } else {
            item.pay_price = item.goods_price
            item.littleCount = item.goods_price * 1
          }
        })
        this.privilegeList = list
        console.log(this.privilegeList,"privilegeList")
        this.$set(this.privilegeList[0], 'prestock_type', 1);
        this.$set(this.privilegeList[0], 'business_type', 1);
        this.$set(this.privilegeList[0], 'business_two_type', 0);
        this.$set(this.privilegeList[0], 'youhui', 1);
        console.log(this.privilegeList,"privilegeList22")
        this.getSummaries('privilege', 'againCount')
      })
      this.detail.yhj_id = val.id
    },

    // 点击提交订单
    isPrivilege () {
      console.log(this.privilegeList,"this.privilegeList")
      if (this.privilegeList.length && this.privilegeType == 1) {
        this.submitPrivilege()
      } else {
        this.submitOrder()
      }
    },
    // 提交优惠券
    submitPrivilege () {
      let project_type = 0
      let service_number = 0
      let start_time = 0
      let end_time = 0
      console.log(this.privilegeList,"111")
      this.privilegeList.forEach(item => {
        if (item.is_project == 2) {
          if (
            !item.project_type &&
            !item.service_number &&
            item.id != 170 && item.id != 171
          ) {
            project_type += 1
          }
          if (
            item.project_type == 1 &&
            !item.service_number &&
            !item.service_number &&
            item.id != 170 && item.id != 171
          ) {
            service_number += 1
          }
          if (item.project_type == 2 && !item.start_time) {
            start_time += 1
          }
          if (item.project_type == 2 && !item.end_time) {
            end_time += 1
          }
        }
      })
      if (
        project_type > 0 ||
        service_number > 0 ||
        start_time > 0 ||
        end_time > 0
      ) {
        this.$message({
          message: '请完善商品列表',
          type: 'error'
        })
      } else {
        this.submitOrder()
      }
    },
    // 提交
    submitOrder () {
      console.log(this.privilegeList,"222")

      this.detail.goodsList.forEach(item => {
        item.house_code = this.detail.house_code
        item.house_code_name = this.detail.house_code_name
        item.is_coupon_goods = 0
        item.desc = '创建订单'
      })

      

      this.detail.goodsList = this.detail.goodsList.concat(this.privilegeList)
      this.detail.total_amount = this.detail.orderTotal
      this.detail.discount_amount = this.privilegeTotal
      this.detail.order_amount =
        this.detail.business_type == 1
          ? this.accounts.zc_allgoods_z
          : this.accounts.zc_allfuwu_z
      this.detail.pay_amount =
        this.detail.business_type == 1
          ? this.accounts.zc_goods_y
          : this.accounts.zc_fuwu_y
      this.detail.gift_amount =
        this.detail.business_type == 1
          ? this.accounts.zc_goods_z
          : this.accounts.zc_fuwu_z
      this.detail.shop_transfer_amount =
        this.detail.business_type == 1
          ? this.accounts.zc_goods_d
          : this.accounts.zc_fuwu_d
      this.hint('创建此订单')
    },
    // 提示
    hint (text) {
      this.$confirm(`${text}，是否确定？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.confirm()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 确定新增
    async confirm () {
      const loading = this.$loading({
        lock: true,
        text: '提交中，请稍后...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      try {
        const res = await createOrder({ ...this.detail })
        loading.close()
        this.$message({
          type: 'success',
          message: res.msg
        })
        this.back()
      } catch (e) {
        console.log(e,"调接口出错")
        this.detail.goodsList = this.detail.goodsList.filter(item => item.youhui != 1)
        console.log(this.detail.goodsList,"goodList")
        loading.close()
      }
    },

    // 返回
    back () {
      const query = {
        detail: this.$route.query.detail,
        id: this.$route.query.detail.id,
        activeName: 'thirdly'
      }
      this.$router.push({ name: '客户详情', query })
    }
  }
}
</script>

<style lang="scss" scoped>
.privilegeList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .privilegeItem {
    width: 460px;
    height: 110px;
    color: #33bea7;
    border-radius: 5px;
    background: #e5f7f4;
    display: flex;
    margin-bottom: 20px;
    cursor: pointer;
    .privilegeL {
      border-right: 1px dotted #33bea7;
      width: 100px;
      height: 100%;
      position: relative;
      padding: 40px 10px;
      text-align: center;
      box-sizing: border-box;
      .privilegeMoney {
        font-size: 20px;
        color: darkcyan;
      }
      .round1 {
        background: #fff;
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
      .round2 {
        background: #fff;
        position: absolute;
        bottom: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    .privilegeR {
      padding: 20px 24px;
      width: 360px;
      box-sizing: border-box;
      .privilegeName {
        font-size: 15px;
        font-weight: bold;
        color: darkcyan;
        display: flex;
        justify-content: space-between;
      }
      .privilegeFull {
        margin-top: 20px;
        text-align: right;
      }
      .privilegeTime {
        margin-top: 10px;
        font-size: 12px;
        text-align: right;
      }
    }
  }
  .privilegeItem:hover {
    box-shadow: 0px 0px 10px #ddd;
  }
  .choice {
    background: #eee;
    div {
      color: #999 !important;
    }
    .privilegeL {
      border-right: 1px dotted #999;
    }
  }
}
.return {
  position: fixed;
  right: 20px;
  top: 71px;
  z-index: 999;
  .backImg {
    margin-right: 14px;
    width: 32px;
    height: 100%;
    cursor: pointer;
    vertical-align: middle;
  }
}

::v-deep .el-form-item--mini .el-form-item__content,
.el-form-item--mini .el-form-item__label {
  margin-left: 10px !important;
}
.conMsg {
  margin-bottom: 30px;

  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 34px;
    color: #666;
  }

  .form {
    display: flex;
    flex-wrap: wrap;

    ::v-deep .el-form-item {
      margin-right: 20px;
      display: flex;
    }
  }
  ::v-deep .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    width: 30%;
  }
}
.submit {
  padding-left: 38%;
  .mainBtn {
    margin: 50px 20px;
  }
}
.el-radio {
  margin-right: 8px;
}
.flexSb {
  display: flex;
  justify-content: space-between;
  .mr20 {
    font-weight: bold;
  }
}
</style>
